<template>
<div>
<div class="">
    <NavBar />
   <LandingPageComponent   />
   <!-- <Cards   />
 -->
    <CommingSoon />
     <Footer/>
</div>
</div>
</template>
<script>
import NavBar from "../components/navBarComponent";
import Footer from "../components/FooterComponent";
 import LandingPageComponent from "../components/landingPageComponent";
//  import Cards from "../components/Cards";

import CommingSoon from "../components/CommingSoon";

export default {
    name: "Home",
    components: {
        NavBar,
        Footer,
      //  Cards,
         LandingPageComponent,
         CommingSoon
    },
}
</script>
<style scoped>
.test {
  /* margin-top: 5rem; */
  /* height: 50rem; */
}
.conatiner{
    background-color: #fcebea;
}
</style>