<template>
  <div class="main ">
    <div class="container">
      <div class="container mb-class" style="margin-top: 0rem !important;">
        <b-navbar toggleable="lg" class="py-0">
          <b-navbar-brand to="/">
            <img class="logo" src="./../assets/BrandAway.png" alt="" />
          </b-navbar-brand>

          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav class="">
            <div class="ml-auto w-100 navbar-items">
              <div class="margin-mobile-link">
                <button type="button" class="btnBuyNow">
                  <a
                    href="https://portal.brandaway.ae/apps/seller"
                    style="color:white;text-decoration: auto;"
                    >Join Now</a
                  >
                  <a
                    href="https://portal.brandaway.ae/apps/seller"
                    class="fas fa-external-link-alt ml-2"
                    style="color:white; margin-left:15px"
                  ></a>
                </button>
              </div>
              <!--<div class="margin-mobile-link" v-if="!showTextBox">
              <router-link
                :class="{
                  'navItems text-center mx-3': true,
                  active: activeLink === 'car-brands',
                }"
                to="/car-brands"
                >Cars</router-link
              >
            </div>
            <div class="margin-mobile-link" v-if="!showTextBox">
              <div class="">
                <b-navbar-nav class="nav-spacing">
                  <b-nav-item-dropdown>
                    <template slot="button-content" class="navItems"
                      ><span class="navItems">My Account</span></template
                    >
                    <b-dropdown-item>
                      <router-link
                        v-if="this.$store.state.isLoggedIn == true"
                        to="/myAds"
                        :class="{
                          'navItems text-center mx-3': true,
                          active: activeLink === 'myAds',
                        }"
                      >
                        <i class="fas fa-shopping-bag mr-2"></i> My Ads
                      </router-link>
                      <router-link
                        v-else
                        to="/login"
                        :class="{
                          'navItems text-center mx-3': true,
                          active: activeLink === 'login',
                        }"
                      >
                        <i class="fas fa-shopping-bag mr-2"></i> My Ads
                      </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <router-link
                        v-if="this.$store.state.isLoggedIn == true"
                        to="/MyFollowing"
                        :class="{
                          'navItems text-center mx-3': true,
                          active: activeLink === '',
                        }"
                      >
                        <i class="fas fa-heart mr-2"></i>Following
                      </router-link>
                      <router-link
                        v-else
                        to="/login"
                        :class="{
                          'navItems text-center mx-3': true,
                          active: activeLink === '',
                        }"
                      >
                        <i class="fas fa-heart mr-2"></i>Following
                      </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <router-link
                        v-if="this.$store.state.isLoggedIn == true"
                        to="/myAnalytic"
                        :class="{
                          'navItems text-center mx-3': true,
                          active: activeLink === '',
                        }"
                      >
                        <i class="bx bxs-bar-chart-alt-2 mr-2"></i>My Analytics
                      </router-link>
                      <router-link
                        v-else
                        to="/login"
                        :class="{
                          'navItems text-center mx-3': true,
                          active: activeLink === '',
                        }"
                      >
                        <i class="bx bxs-bar-chart-alt-2 mr-2"></i>My Analytics
                      </router-link>
                    </b-dropdown-item>
                  </b-nav-item-dropdown>
                </b-navbar-nav>
              </div>
            </div>

            <div class="search-box-container mx-3">
              <div class="search-box">
                <input
                  type="text"
                  class="search-input"
                  :class="{ active: showTextBox }"
                  placeholder="Search"
                  @input="filterCars"
                />
                <i
                  class="fas fa-search search-icon"
                  v-if="!showTextBox"
                  @click="showTextBox = !showTextBox"
                  style="cursor: pointer"
                ></i>
                <i
                  class="fas fa-search"
                  aria-hidden="true"
                  :class="{ activeIcon: showTextBox }"
                  @click="showTextBox = !showTextBox"
                  :style="{ display: showTextBox ? 'block' : 'none' }"
                  style="cursor: pointer"
                ></i>
                <i
                  class="fa fa-times"
                  aria-hidden="true"
                  @click="showTextBox = !showTextBox"
                  :style="{ display: showTextBox ? 'block' : 'none' }"
                  style="
                    cursor: pointer;
                    position: absolute;
                    right: 1rem;
                    top: 0.8rem;
                  "
                ></i>
              </div>
              <div
                class="filtered-result-container shadow"
                :style="{ width: filteredCars.length > 0 && '350px' }"
                v-if="showTextBox"
              >
                <div
                  class="filtered-result px-3"
                  :key="index"
                  v-for="(car, index) in filteredCars"
                >
                  <ResultCard :car="car" />
                </div>
              </div>
            </div>
            <div class="bellIcon" @click="goToNotification()" v-if="this.$store.state.notifications.length != 0">
              <div class="icon">
                <span style="font-size: 22px;"><i class="far fa-bell"></i></span>
                <div class="txt">{{this.$store.state.notificationCount}}</div>
              </div>
            </div>
             <div @click="goToNotification()" v-else>
              <div class="icon">
                <span style="font-size: 22px;"><i class="far fa-bell"></i></span>
              </div>
            </div>
             <div class="margin-mobile-link mobNotification">
              <router-link
                :class="{
                  'navItems text-center mx-3': true,
                  active: activeLink === 'notifications',
                }"
                to="/notifications"
                >Notification</router-link
              >
            </div>
            <div>
              <router-link
                to="/login"
                v-show="this.$store.state.isLoggedIn == false"
                class="routerBtnAddNew btn-shadow px-4 py-2 mx-2 mb-1 rounded"
                lg
                >Add new car</router-link
              >
              <router-link
                to="/AddNew"
                @click.native="NewPost()"
                v-show="this.$store.state.isLoggedIn == true"
                class="routerBtnAddNew btn-shadow px-4 py-2 mx-2 mb-1 rounded"
                lg
                >Add new car</router-link
              >
              <router-link
                to="/login"
                v-if="this.$store.state.isLoggedIn == false"
                class="routerBtnLogin px-4 py-2 btn-shadow text-white rounded"
              >
                Login
              </router-link>
              <router-link
                to="/login"
                v-else
                @click="this.$store.commit('isLoggedIn', false)"
                class="routerBtnLogin px-4 py-2 btn-shadow text-white rounded"
              >
                Logout
              </router-link>
            </div> -->
            </div>

            <b-navbar-nav class=""> </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationBar",
  props: ["activeLink"],
};
</script>
<style>
.btnBuyNow {
  background-color: black;
  color: white;
  font-size: 1rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}
@media screen and (max-width: 1600px) {
  /* .main {
  height: 8vh !important;
} */
  .btnBuyNow {
    /* width: 134%;
    font-size: 1.2rem;
    padding: 0.7rem; */
    font-size: 1rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
}
.navbar-light .navbar-nav .nav-link {
  color: #818181 !important;
  font-weight: 500;
}
</style>
<style scoped>
/* .navbar-light .navbar-nav .nav-link {
    color: #818181 !important;
} */
.icon {
  width: 30px;
  height: 30px;
  position: relative;
}
.txt {
  /* background-color: red;
    font-size: xx-small;
    position: absolute;
    padding: 2px;
    top: -5px;
    left: 14px;
    border-radius: 25px;
    color: white; */
  background-color: red;
  font-size: 10px;
  font-weight: 300;
  position: absolute;
  /* padding: 2px; */
  top: -1px;
  left: 11px;
  border-radius: 50%;
  color: white;
  width: 50%;
  text-align: center;
}
.notification {
  margin-right: 8px;
}
.main {
  background-color: #fcebea;
  position: sticky;
  top: 0;
  z-index: 10000;
  width: 100%;
  /* height: 5.5rem; */
  border-bottom: 0.01rem solid #cfcfcf;
  height: auto !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
  /* box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2); */
}

.routerBtn:hover {
  text-decoration: none;
}
.navItems {
  color: #000;
  text-transform: capitalize;
  font-weight: 500;
  text-decoration: none;
  font-size: 17px;
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.35s;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.navItems:hover {
  border-bottom: 4px solid rgb(236, 172, 45);
}

.active {
  border-bottom: 4px solid rgb(236, 172, 45);
}

.innerSection {
  position: absolute;
  /* right: 0px; */
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  width: 100% !important;
}

.mb-class {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.logo {
  color: #438ccb;
}
.innerContainer {
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  /* background: red; */
  /* position: absolute; */
  /* left: 27.75%; */
  /* right: 0; */
  /* left: 20%; */
  /* transform: translateX(-70%); */
  /* width: 50%; */
  /* background: crimson; */
  position: absolute;
  right: 0;
}
.subMainContainer {
  width: 90%;
  margin: 0 auto;
  text-align: center;
}
.logo {
  /* width: 150px; */
  /* width: 77px; */
  width: 17rem;
  /* margin: -50px -50px -50px; */
  margin: -50px 0px -50px;
  /* height: 150px; */
  object-fit: contain;
}

.btn-shadow {
  box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.2);
}

.navbar-items {
  display: flex;
  justify-content: end;
  align-items: center;
}

.search-box-container {
  position: relative;
}
.search-box-container input[type="text"] {
  border: 0;
  padding: 0;
  width: 0;
  height: 40px;
  border-radius: 5px;
  transition: 0.3s;
  padding-right: 1rem;
  display: none;
}

.search-box-container i {
  font-size: 16px;
}

.filtered-result-image {
  width: 25%;
  overflow: hidden;
  margin-right: 5px;
}

.search-result-image {
}

.filtered-result-container {
  background-color: white;
  position: absolute;
  max-height: 400px;
  max-width: 350px;
  border-radius: 10px;
  overflow: auto;
}

.search-box-container i.activeIcon {
  position: absolute;
  left: 0.8rem;
  top: 0.8rem;
}

.search-box-container i.activeIconCross {
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
}

.result-card:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}

.search-box-container input[type="text"].active {
  width: 350px;
  padding: 0 2rem;
  transition: all 0.3s 0.2s;
  border: 1px solid #919191;
  display: block;
}

.routerBtnAddNew {
  background: #202c3a;
  outline: none;
  border: none;
  color: white;
  font-family: "Poppins", sans-serif;
}
.routerBtnLogin {
  background: #ecac2d;
  outline: none;
  border: none;
}

.btn-shadow .innerContainer {
}
@media screen and (max-width: 993px) {
  .mobNotification {
    display: block !important;
  }
  .bellIcon {
    display: none !important;
  }
  .innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    width: 90%;
    left: 0;
  }
  .navbar-items {
    flex-direction: column;
    width: 100% !important;
    align-items: flex-start;
    margin: 15px 0;
  }
  .navItems {
    margin: 10px 0 !important;
  }
  .margin-mobile-link {
    margin: 10px 0;
  }
  /* .logo{
    width: 90px;

  } */
  .navbar-light .navbar-toggler {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .mobNotification {
    display: block !important;
  }
  .bellIcon {
    display: none !important;
  }
  .search-box-container {
    display: none;
  }
  nav.navbar.py-2.navbar-light.navbar-expand-lg {
    padding: 0 !important;
  }
  .navbar-collapse {
    /* background-color: #fff; */
    background-color: #fcebea;
    width: 300px;
    width: 100%;
    position: absolute;
    top: 66px;
    padding: 10px;
  }
  .routerBtnAddNew {
    margin-left: 0 !important;
  }
  .mb-class {
    padding: 0 16px !important;
  }
}
@media screen and (min-width: 994px) {
  .mobNotification {
    display: none !important;
  }
  .bellIcon {
    display: block !important;
  }
}
@media screen and (min-width: 1600px) {
  .mobNotification {
    display: none !important;
  }
  .bellIcon {
    display: block !important;
  }
  .innerContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* background: red; */
    position: absolute;
    /* left: 30.75%; */
    /* width: 30%; */
  }
}
@media screen and (min-width: 991px) and (max-width: 1174px) {
  .innerContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* background: red; */
    /* position: absolute; */
    /* left: 16.75%; */
    /* width: 50%; */
  }

  @media screen and (min-width: 360px) and (max-width: 420px) {
    .logo {
      /* width: 150px; */
      width: 67px;
      margin: -58px -12px -50px;
      /* height: 150px; */
      -o-object-fit: contain;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: 560px) {
  .logo {
    width: 12rem;
  }
  .navbar-collapse {
    background-color: #fcebea;
  }
  .navbar-light .navbar-toggler {
    display: none;
  }
  .navbar-brand {
    margin-right: 0rem;
  }
  .navbar {
    justify-content: center;
  }
}
</style>
