<template>
  <div>
    <div style="text-align: center;" class="mainDiv">
      <div class="container">
        <div class="myGrid">
          <div class="box1">
            <h3 class="tagLine" v-scrollanimation>
              A marketplace for all your needs!
            </h3>

            <div
              v-scrollanimation
              class="desktopJoinNow"
              style="text-align: left"
            >
              <button type="button" class="btnJoinNow">
                <a
                  href="https://portal.brandaway.ae/apps/seller"
                  style="color: white; text-decoration: auto"
                  >Start Selling</a
                >
                <i
                  class="fas fa-external-link-alt ml-2"
                  style="color: white; margin-left: 10px"
                ></i>
              </button>
            </div>
            <div v-scrollanimation class="export">
              Export
              <div class="quality">Quality</div>
            </div>
          </div>
          <div class="box2">
            <div class="h5" style="" v-scrollanimation>INSTAGRAM</div>
            <hr v-scrollanimation class="header" />

            <i
              v-scrollanimation
              class="fab fa-instagram instaIcon"
              style=""
            ></i>
          </div>
          <div class="box3">
            <div class="mobJoinNow">
              <button type="button" class="btnJoinNow">
                <a
                  href="https://portal.brandaway.ae/apps/seller"
                  style="color: white; text-decoration: auto; width: 40%"
                  >Join Now</a
                >
                <i
                  class="fas fa-external-link-alt ml-2"
                  style="color: white"
                ></i>
              </button>
            </div>
            <div class="m-3">
              <img
                v-scrollanimation
                class="image"
                src="https://dk0pm9zdlq16s.cloudfront.net/bcd0f659-98c3-42de-8c5a-772afbbba7f9.png"
                alt=""
              />
            </div>
            <div class="m-3">
              <img
                v-scrollanimation
                class="image"
                src="https://dk0pm9zdlq16s.cloudfront.net/b6392791-d948-4de3-bdbc-bf2b48332378.png"
                alt=""
              />
            </div>
            <div class="m-3">
              <img
                v-scrollanimation
                class="image"
                src="https://dk0pm9zdlq16s.cloudfront.net/687457d9-1547-4cb6-9963-a387d8f9f085.png"
                alt=""
              />
            </div>
          </div>
          <!-- <div class="box4">
           
            <h4  class="line2">
              Explore products from home businesses
            </h4>
          </div> -->
          <div class="box5">
            <div style="">
              <div>
                <i class="fas fa-quote-right" style="color: #F88379"></i>
              </div>
              <div class="dfcss">
                <div class="w-10">
                  <i
                    class="fas fa-bomb"
                    style="color: #F88379; font-size: 3rem"
                  ></i>
                </div>
                <div v-scrollanimation class="feedBack w-70">
                  Now I can try the fitting room at the comfort of my house.
                  Super easy to use with quick delivery!
                </div>
              </div>
              <div v-scrollanimation class="by">Lisa - Abu Dhabi</div>
              <img
                v-scrollanimation
                class="box5Img"
                src="./../assets/girl2_crop.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section2">
      <div class="container">
        <div class="mycard">
          <div class="Image m-2" v-scrollanimation>
            <img
              v-
              class="Image_img"
              src="https://dk0pm9zdlq16s.cloudfront.net/391f05fe-cc8b-46e0-8ae0-9591e8e69fd9.png"
              alt=""
            />
            <div class="overlay">
              <div class="title">Stores and brands</div>
              <div class="subTitle">
                Are you a store owner looking to boost sales and reach more
                audience?
              </div>
            </div>
          </div>
          <div class="Image m-2" v-scrollanimation>
            <img
              class="Image_img"
              src="https://dk0pm9zdlq16s.cloudfront.net/3b514ddd-5770-4d39-8012-c86622bcb992.png"
              alt=""
            />
            <div class="overlay">
              <div class="title">Salon owners</div>
              <div class="subTitle">
                Bring more sales to your salon. Join our marketplace now.
              </div>
            </div>
          </div>
        </div>
        <div class="mycard">
          <div class="Image m-2" v-scrollanimation>
            <img
              class="Image_img"
              src="https://dk0pm9zdlq16s.cloudfront.net/6e987775-3aa0-47a4-a03d-7887871bcd48.png"
              alt=""
            />
            <div class="overlay">
              <div class="title">Home businesses</div>
              <div class="subTitle">
                A perfect place for home businesses to thrive their business.
              </div>
            </div>
          </div>
          <div class="Image m-2" v-scrollanimation>
            <img
              class="Image_img"
              src="https://dk0pm9zdlq16s.cloudfront.net/4a05b0f5-96d6-4f3b-a8c2-ee6c42e0f796.png"
              alt=""
            />
            <div class="overlay">
              <div class="title">Fitting room</div>
              <div class="subTitle">
                A offer this unique service to our users to try the product at
                the comfort of their homes.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { BCard, BCardText,BCol,BRow} from 'bootstrap-vue'
export default {
  components: {
    //BJumbotron,
    // BCard,
    // BCardText,
    // BCol,BRow
  },
  setup() {},
  methods: {
    Join() {
      window.location.href = "https://portal.brandaway.ae/apps/seller";
    },
  },
};
</script>

<style scoped>
.section2 {
  padding: 3rem;
  background-color: #fcebea;
}
.mycard {
  display: flex;
}
.Image {
  position: relative;
  width: 95%;
}
.Image_img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 2%;
}
.title {
  font-size: 2.5rem;
  /* font-weight: bold; */
  /* text-align: right; */
  padding-left: 3rem;
  padding-top: 3rem;
}
.subTitle {
  font-size: 1.2rem;
  /* font-weight: bold; */
  /* text-align: left;*/
  padding-left: 3rem;
  padding-top: 2rem;
}
.btn {
  background: white;
  color: #414141;
  width: 25%;
  margin-left: 3rem;
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 400;
}
.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffff;
  cursor: pointer;
  border-radius: 2%;
}
.mainDiv {
  background-color: #fcebea;
  background-image: url("../assets/new-image-1.png");
  background-size: 74rem;
  background-position: bottom;
  background-repeat: no-repeat;
  background-position-x: left;
}
.myGrid {
  display: grid;
  grid-template-columns: 1fr 0.8fr 0.8fr 0.8fr;
  grid-template-rows: 0.3fr 0.3fr 1fr;
  /* grid-template-rows: 0.4fr 0.6fr 1fr; */
  grid-template-areas:
    "box1 box2 box2 box2"
    "box1 box3 box3 box3"
    "box1 box4 box4 box5";
}
.header {
  width: 25%;
  margin-top: 12px;
  margin-left: 1rem;
  margin-right: 1rem;
}
.box1 {
  grid-area: box1;
}
.tagLine {
  font-size: 4rem;
  font-weight: 900;
  margin-top: 4rem;
  margin-bottom: 2rem;
  font-family: "Do Hyeon", sans-serif;
  color: #414141;
  text-align: left;
}
.btnJoinNow {
  background-color: black;
  color: white;
  font-size: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.h5 {
  font-weight: 700;
  font-size: 1.25rem;
}
.quality {
  margin-left: 4rem;
}
.box2 {
  grid-area: box2;
  display: flex;
  margin-top: 4rem;
  justify-content: end;
}
.instaIcon {
  font-size: 27px;
  font-weight: 700;
}
.box3 {
  grid-area: box3;
  display: flex;
  margin-top: 4rem;
}
.image {
  width: 20rem;
  height: 16rem;
}
.box4 {
  grid-area: box4;
}
.line2 {
  text-align: right;
  font-size: 2rem;
  padding-top: 22rem;
  width: 65%;
  margin-left: 7rem;
  font-family: "Do Hyeon", sans-serif;
}
.export {
  margin-top: 2rem;
  font-size: 3rem;
  text-align: left;
  font-weight: 700;
  font-family: "Sacramento", cursive;
}
.box5 {
  grid-area: box5;
  padding-top: 4rem;
}
.feedBack {
  width: 17rem;
  font-size: 1.3rem;
  text-align: left;
  font-weight: 800;
  margin-left: 2rem;
}
.box5Img {
  margin-top: 3rem;
  width: 22rem;
}
.by {
  margin-right: 2rem;
  margin-top: 1rem;
}
.dfcss {
  margin-left: 0rem;
  display: flex;
}
.mobJoinNow {
  text-align: center;
  display: none;
}
.holder {
  position: relative;
  width: 200px;
  height: 50px;
}
.line {
  position: absolute;
  bottom: -67px;
  width: 144px;
  /* Height set to clip correctly basically background-size/2 + line width */
  height: 20px;
}
.line1 {
  background: linear-gradient(
    45deg,
    transparent,
    transparent 59%,
    #0dcaf0 49%,
    transparent 51%
  );
}
.line_2 {
  background: linear-gradient(
    -45deg,
    transparent,
    transparent 59%,
    #0dcaf0 49%,
    transparent 51%
  );
}
.line {
  /* Must be after background definition. */
  background-size: 36px 50px;
}
.zigzag {
  position: absolute;
  bottom: -67px;
  width: 144px;
  /* Height set to clip correctly basically background-size/2 + line width */
  height: 20px;
}
.zigzag-line1 {
  background: linear-gradient(
    45deg,
    transparent,
    transparent 59%,
    #0dcaf0 49%,
    transparent 51%
  );
}
.zigzag-line_2 {
  background: linear-gradient(
    -45deg,
    transparent,
    transparent 59%,
    #0dcaf0 49%,
    transparent 51%
  );
}
.zigzag {
  /* Must be after background definition. */
  background-size: 36px 50px;
}
/*
    This classes are for the directive. 
    For each element observed by our directive, the before-enter class is added.
  */
.before-enter {
  opacity: 0;
  transform: scale(0.5) rotatex(-25deg);
  transition: all 1s ease-out;
}
/* 
    If the element intersects with the viewport, the before-enter class is added.
  */
.enter {
  opacity: 1;
  transform: scale(1) rotatex(0deg);
}
@media screen and (min-width: 1163px) and (max-width: 1700px) {
  .mainDiv {
    background-size: 47rem;
  }
  .myGrid {
    grid-template-columns: 1fr 0.8fr 0fr 1fr;
    grid-template-rows: 0.3fr 0.3fr 0.5fr;
    grid-template-areas:
      "box1 box2 box2 box2"
      "box1 box3 box3 box3"
      "box1 box4 box4 box5";
  }
  .box2 {
    margin-top: 2rem;
  }
  .box3 {
    margin-top: 2rem;
  }
  .box4 {
  }
  .export {
    margin-top: 1rem;
    font-size: 2rem;
  }
  .by {
    margin-right: 6rem;
  }
  .dfcss {
  }
  .tagLine {
    font-size: 3.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .line2 {
    padding-top: 15rem;
    font-size: 1.5rem;
    width: 66%;
    margin-left: -5rem;
  }
  .image {
    width: 14rem;
    height: 10rem;
  }
  .btnJoinNow {
    font-size: 1rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
  .box5 {
    padding-top: 1rem;
  }
  .box5Img {
    margin-top: 1rem;
  }
  .feedBack {
    margin-left: 3rem;
    font-size: 1rem;
  }
}
/* @media screen and (max-width: 765px) { */
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .mycard {
    display: block;
  }
  .mainDiv {
    padding-bottom: 70px;
  }
  .section2 {
    /* padding-top: 10rem; */
    padding: 0.75rem;
    /* margin-top: 70px; */
  }
  .title {
    font-size: 1.5rem;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
  }
  .subTitle {
    font-size: 1rem;
    padding-left: 1.5rem;
    padding-top: 1rem;
  }
  .box5 {
    /* margin-bottom: 100px; */
    height: 300px;
    /* padding: 100px; */
  }
  .btn {
    width: 37%;
    margin-left: 2rem;
    margin-top: 0.4rem;
    padding: 0.3rem;
  }
  .mainDiv {
    background-size: 50rem;
    background-position: top;
    background-position-x: inherit;
  }
  .myGrid {
    height: 77rem;
    grid-template-columns: 0.5fr 0.5fr;
    grid-template-rows: 0.3fr 0.3fr 0.3fr 0.3fr;
    grid-template-areas:
      "box1 box2"
      "box3 box3"
      "box4 box4"
      "box5 box5";
  }
  .tagLine {
    font-size: 1.8rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .btnJoinNow {
    font-size: 0.7rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 40%;
  }
  .h5 {
    font-size: 0.6rem !important;
  }
  .header {
    width: 0%;
    margin-top: 0px;
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .quality {
    margin-left: 2rem;
  }
  .export {
    margin-top: 0.5rem;
    font-size: 1.4rem;
  }
  .box2 {
    padding-left: 3rem;
    margin-top: 1rem;
  }
  .instaIcon {
    font-size: 0.7rem;
    font-weight: 700;
    padding-left: 0.5rem;
  }
  .box3 {
    margin-top: 3rem;
    display: block;
  }
  .dfcss {
    margin-left: 1rem;
    display: flex;
    padding-left: 0rem;
  }
  .image {
    width: 100%;
    height: 15rem;
    object-fit: fill;
  }
  .line2 {
    text-align: center;
    font-size: 1.5rem;
    padding-top: 0rem;
    width: 78%;
    margin-left: 0rem;
    margin: auto;
  }
  .box5 {
    padding-top: 0rem;
  }
  .feedBack {
    width: 70%;
    font-size: 0.7rem;
    font-weight: 800;
    margin: auto;
  }
  .by {
    font-size: 0.6rem;
    width: 47%;
    margin: auto;
    text-align: left;
  }
  .box5Img {
    margin-top: 0rem;
    width: 12rem;
  }
  .mobJoinNow {
    display: block !important;
    margin-bottom: 2rem;
  }
  .desktopJoinNow {
    display: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1150px) {
  .mainDiv {
    background-size: 59rem;
    background-position: bottom;
    background-position-x: right;
  }
  .myGrid {
    grid-template-columns: 1fr 0.8fr 1fr;
    grid-template-rows: 0.2fr 0.2fr 0.5fr;
    grid-template-areas:
      "box1 box1 box2"
      "box1 box3 box3"
      "box4 box4 box5";
    height: 76rem;
  }
  .tagLine {
    font-size: 4rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }
  .btnJoinNow {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .box3 {
    grid-area: box3;
    display: flex;
    margin-top: -2rem;
    overflow-x: scroll;
    margin-left: 1rem;
  }
  .line2 {
    text-align: left;
    font-size: 2rem;
    padding-top: 9rem;
    width: 155%;
    margin-left: -12rem;
  }
  .box5 {
    padding-top: 8rem;
  }
  .box5Img {
    margin-top: 4rem;
    width: 21rem;
  }
  .section2 {
    padding: 2rem;
  }
  .title {
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }
  .btn {
    color: #414141;
    width: 37%;
    margin-left: 1rem;
    margin-top: 1rem;
    padding: 0.2rem;
    border-radius: 0.3rem;
  }
}
</style>
