<template>
  <div>
    <!-- desktop view -->
    <div class="d-none d-md-block d-lg-block myAppContainer">
      <b-row class="pt-5">
        <b-col cols="1"></b-col>
        <b-col cols="11">
          <b-row>
            <b-col cols="7">
              <b-row>
                <b-col cols="9">
                  <div>
                    <div class="overline smallText">
                      TAKE US EVERYWHERE WITH YOU
                    </div>
                    <h2 class="sectionSevenH1 mediumText">
                      Brandaway App!
                    </h2>
                    <p class="mediumText">
                      Shop with ease with our App. Buy from stores and home
                      businesses. Book salon appointments and get service at
                      home.
                    </p>
                  </div>
                  <b-row
                    style="position: relative; transform: translate(-40px)"
                  >
                    <b-col cols="1"></b-col>
                    <b-col cols="10" class="">
                      <b-row class="pt-3 pb-5">
                        <b-col cols="6">
                          <img
                            style="width: 75%"
                            alt="Download iOS App"
                            data-v-05a66e7a=""
                            src="https://d1nerowq7l0kro.cloudfront.net/ios.jpg"
                            lazy="loaded"
                          />
                        </b-col>
                        <b-col cols="6">
                          <img
                            style="width: 75%"
                            alt="Download iOS App"
                            data-v-05a66e7a=""
                            src="https://d1nerowq7l0kro.cloudfront.net/android.jpg"
                            lazy="loaded"
                            class="leftAppImage"
                          />
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col cols="1"></b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="5">
              <img
                src="https://dk0pm9zdlq16s.cloudfront.net/a24b5524-a2d6-442e-a1bc-37e377fc1705.png"
                alt=""
                class="imgMob"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1"></b-col>
      </b-row>
      <!-- <b-row class="">
        <b-col md="2"></b-col>
        <b-col md="5" class="mt-5">
          <div class="overline smallText">TAKE US EVERYWHERE WITH YOU</div>
          <h2 class="sectionSevenH1 mediumText">
            Get the Day Cleaner° App now!
          </h2>
          <p class="font mediumText">
            Book and manage your appointments with a few taps, view your
            professional's profile and ratings, see real-time location of your
            professional and much more.
          </p>
          <div class="btnsBox">
            <img
              class="btn"
              src="../../assets/download-app-badges-google.png"
              alt=""
              style="width: 200px; margin-left: -12px"
            />
            <img
              class="btn"
              src="../../assets/download-app-badges-ios.png"
              alt=""
              style="width: 200px; margin-left: -40px"
            />
          </div>
        </b-col>
        <b-col md="3" class="imagePhoto">
          <img
            src="/img/DownloadSevenRightImg.b437a9a6.png"
            alt=""
            class="imgMob"
          />
        </b-col>
        <b-col md="2"></b-col>
      </b-row> -->
    </div>

    <!-- 

  __  __       _     _ _              _               
 |  \/  | ___ | |__ (_) | ___  __   _(_) _____      __
 | |\/| |/ _ \| '_ \| | |/ _ \ \ \ / / |/ _ \ \ /\ / /
 | |  | | (_) | |_) | | |  __/  \ V /| |  __/\ V  V / 
 |_|  |_|\___/|_.__/|_|_|\___|   \_/ |_|\___| \_/\_/  
                                                      

      -->
    <br />

    <div class="d-block d-md-none d-lg-none d-xl-none pt-5 p-2">
      <b-row>
        <b-col cols="12">
          <p class="text-uppercase overline has-text-black38 px-3 smallText">
            Take us everywhere with you
          </p>
          <br />
          <div>
            <h3
              class="h3-text-item has-text-black87 px-3"
              style="
                font-size: 1.875rem;
                font-weight: 700;
                font-style: normal;
                font-stretch: normal;
                line-height: 2.8rem;
                letter-spacing: normal;
                font-family: HKGrotesk, sans-serif !important;
                margin-top: -30px;
              "
            >
              Brand Away App Coming Soon!
            </h3>
            <p
              class="body-text-item has-text-black87 fsImp px-3"
              style="
                font-size: 15px;
                line-height: 1.8rem;
                letter-spacing: 1px;
                font-family: 'HK Grotesk', sans-serif;
              "
            >
              Book and manage your appointments with a few taps, view your
              professional’s profile and ratings, see real-time location of your
              professional and much more.
            </p>
          </div>

          <b-row
            class="text-center mx-4 pb-5"
            style="
              padding-bottom: 0rem !important;
              position: relative;
              top: 18px;
              transform: translate(-14px);
            "
          >
            <b-col cols="6" class="px-3">
              <div class=" sectionSevenBtn">
                <img
                  class="SectionSevenImage"
                  src="https://appick.s3.us-east-2.amazonaws.com/ios.jpg"
                  alt=""
                  style="width: 130px"
                />
              </div>
            </b-col>
            <b-col cols="6" class="px-3 leftAppImageMobile">
              <div class="sectionSevenBtn">
                <img
                  class="SectionSevenImage"
                  src="https://appick.s3.us-east-2.amazonaws.com/android.jpg"
                  alt=""
                  style="width: 130px"
                />
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-container>
            <img
              src="https://dk0pm9zdlq16s.cloudfront.net/a24b5524-a2d6-442e-a1bc-37e377fc1705.png"
              alt=""
              class="mt-5"
              style="width: 100vw; margin-bottom: -10px; z-index: 0"
            />
          </b-container>
        </b-col>
      </b-row>
    </div>
    <!--  -->
  </div>
</template>

<script>
//import Vue from 'vue'
import { BRow, BCol } from "bootstrap-vue";
export default {
  components: {
    //BJumbotron,
    BRow,
    BCol,
  },
};
</script>
<style scoped>
.fsImp {
  font-size: 16px;
}
.leftAppImage {
  position: relative;
  transform: translateX(-60px);
}
.leftAppImageMobile {
  position: relative;
  transform: translateX(-30px);
}

.SectionSevenImage {
  /* width: 9rem; */
  /* height: 50px; */
  position: relative;
  transform: translateX(25px);

  cursor: pointer;
}
@media screen and (max-width: 375px) {
  .phonexs {
    height: 230px;
    width: 100%;
  }
}

@media screen and (max-width: 411px) {
  .phonesm {
    height: 230px;
    width: 100%;
  }
}
.overline {
  font-size: 0.75rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1rem;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.38) !important;
  font-family: HKGrotesk, sans-serif !important;
}
.sectionSevenH1 {
  font-size: 29px;
  font-weight: 600;
  word-spacing: 2.6px;
  text-align: start;
  padding-top: 11px;
  margin-bottom: 1.25rem;
  font-family: HKGrotesk, sans-serif !important;
}
.font {
}
.btnsBox {
  margin-top: -3rem !important;
  margin-left: -0.6rem !important;
}
.btn {
  /* width: 9rem; */
  margin-right: 1rem;
  /* padding: 0px !important; */
}
.imgMob {
  width: 24rem;
  /* height: 30rem;
  object-fit: contain; */
  position: relative;
  /* background: crimson; */
  top: 20px;
  /* bottom: 0; */
  z-index: -30px;
}
#mobView {
  display: none;
}

/* .SectionSevenImage {
  width: 10rem;
  height: 50px;
  object-fit: contain;
  position: relative;
  top: -30px;
} */
@media screen and (max-width: 480px) {
  .desktopView_1180 {
    display: none !important;
    /* margin-right: 9rem;
    margin-left: 5rem; */
  }
  .sectionSevenH1 {
    font-size: 16px;
    font-weight: 600;
    width: 100%;

    /* color: red; */
    padding-top: 11px !important;
    font-family: HKGrotesk, sans-serif !important;
    /* background: white; */
    /* word-spacing: 10px; */
  }
  .font {
  }
  #mobView {
    display: block !important;
  }
  #mobileImg {
    width: 113%;
    width: 24rem !important;
    /* width: 700px;
    height: 200px; */
    /* object-fit: contain; */
    position: relative;
    top: 6px;
    /* display: none; */
  }
  #mobileBtns {
    margin-left: 11px;
  }
}
@media screen and (max-width: 2190px) and (min-width: 1190px) {
  .desktopView_1180 {
    display: block;
    position: relative;
  }
  .rowSpacing {
    margin-left: 0rem;
    padding-left: 0rem;
    padding-left: 0rem;
    position: relative;
    /* top: 25px; */
  }
  .sectionSevenH1 {
    font-size: 29px;
    font-weight: 600;
    word-spacing: 2.6px;
    text-align: start;
    padding-top: 11px;
    margin-bottom: 1.25rem;
    font-family: HKGrotesk, sans-serif !important;
  }
  .font {
  }
}
@media screen and (max-width: 1670px) and (min-width: 768px) {
  .rowSpacing {
    margin-left: 0px !important;
    padding-left: 0rem !important;
    position: relative;
    top: 25px;
  }
  .font {
    /* font-size: 18px !important;
    padding-right: 4rem !important;
    text-align: start !important;
    font-family: HKGrotesk, sans-serif !important; */
  }
}
@media screen and (max-width: 1030px) and (min-width: 1000px) {
  .rowSpacing {
    margin-left: 0px !important;
    padding-left: 0rem !important;
    margin-right: 2rem;
    position: relative;
    /* top: 20px; */
  }
  .sectionSevenH1 {
    font-size: 22px;
    font-weight: 600;
    word-spacing: 2px;
    text-align: start;
    padding-top: 11px;
    font-family: HKGrotesk, sans-serif !important;
    /* margin-bottom: 1.25rem; */
  }
  .font {
  }
  .btnsBox {
    margin-top: -3rem !important;
    margin-left: -1.3rem !important;
  }
  .btn {
    width: 9rem !important;
    margin-right: -0.3rem !important;
    padding: 0px !important;
  }
  /* .imgMob {
    width: 20rem;
  } */
  #mobView {
    display: none !important;
  }
}
@media screen and (min-width: 769px) {
  #desktopBtns {
    display: block !important;
    margin-right: 6rem !important;
    margin-top: -2rem !important;
    /* justify-content: start; */
  }
  #mobileBtns {
    display: none !important;
  }
  #mobileImg {
    width: 67%;
    /* width: 64%; */
    align-self: start !important;
  }
}
</style>
