<template>
  <div>
    <div class="main ">
      <div class="container">
        <div class="sections">
          <div class="container" v-for="section in sections" :key="section">
            <h5 v-scrollanimation class="pb-2" style="padding-left: 1.8rem;">
              {{ section.heading }}
            </h5>
            <ul v-for="point in section.points" :key="point">
              <li v-scrollanimation>{{ point }}</li>
            </ul>
            <hr class="seperator" />
          </div>
        </div>
        <hr class="seperator2" v-scrollanimation />
        <div class="container footerEnd1" v-scrollanimation>
          <div style="white-space: nowrap;">© 2022 Brandaway, Inc.</div>
          <ul style="overflow: hidden; list-style-type: disc;">
            <li style="float:left;padding-right: 2rem">Privacy</li>
            <li style="float:left;padding-right: 2rem">Terms</li>
            <li style="float:left;padding-right: 2rem">Sitemap</li>
          </ul>
          <div class="conatiner " style="width: 30%"></div>
          <!-- <div class="col-2">
            <li class="fas fa-globe" style="margin-right:1rem"></li>
            English
          </div> -->
          <!-- <div class="col-1">
            <i class="fas fa-dollar-sign" style="margin-right:1rem"></i>US
          </div> -->
          <div class="col-1"></div>
          <div class="col-1"><i class="fab fa-facebook-f"></i></div>
          <div class="col-1"><i class="fab fa-twitter"></i></div>
          <div class="col-1"><i class="fab fa-instagram"></i></div>
        </div>
        <!-- <div class="container footerEnd2"> -->

        <!-- <div style="white-space: nowrap;">© 2021 Brand Away, Inc.</div>
                    <ul style="overflow: hidden; list-style-type: disc;">
                        <li style="float:left;padding-right: 2rem">Privacy</li>
                        <li style="float:left;padding-right: 2rem">Terms</li>
                        <li style="float:left;padding-right: 2rem">Sitemap</li>
                    </ul>
                    <div class="d-flex" style="width: 90%;margin-left: 1rem;margin-right: 1rem;">
                        <div class="col-2"><li class="fas fa-globe" style="margin-right:1rem"></li>English(US)</div>
                        <div class="col-1"><i class="fas fa-dollar-sign" style="margin-right:1rem"></i>US</div>
                    </div>
                    <div class="d-flex mt-2" style="width: 70%;margin-left: 2rem;margin-right: 0rem;">
                        <div class="mx-1"><i class="fab fa-facebook-f"></i></div>
                        <div class="mx-1"><i class="fab fa-twitter"></i></div>
                        <div class="mx-1"><i class="fab fa-instagram"></i></div>
                    </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="container footerEnd2">
      <i class="fab fa-facebook Social_media_icons"></i>
      <i class="fab fa-instagram Social_media_icons"></i>
      <i class="fab fa-twitter Social_media_icons"></i>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sections: [
        {
          heading: "Support",
          points: [
            "Help Center",
            "Safety information",
            "Cancellation options",
            "Our COVID-19 Response",
          ],
        },
        {
          heading: "Community",
          points: [
            "Help Center",
            "Safety information",
            "Cancellation options",
            "Our COVID-19 Response",
          ],
        },
        {
          heading: "Hosting",
          points: [
            "Help Center",
            "Safety information",
            "Cancellation options",
            "Our COVID-19 Response",
          ],
        },
        {
          heading: "Help",
          points: [
            "Help Center",
            "Safety information",
            "Cancellation options",
            "Our COVID-19 Response",
          ],
        },
      ],
      points: [],
    };
  },
};
</script>
<style scoped>
.main {
  background-color: #f2e6e6;
  /* background-color: #bfd9fe45; */
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr;
}
.sections {
  display: flex;
  text-align: left;
}
ul {
  list-style-type: none;
}
.conatiner {
  text-align: left;
}
.seperator {
  display: none;
}
.footerEnd1 {
  display: flex;
  margin-left: 2rem;
  margin-right: 2rem;
}
.footerEnd2 {
  display: none;
  background-color: #fcebea;
}
.Social_media_icons {
  font-size: 2rem;
  color: #ee968e;
  text-align: center;
  margin: 1.5rem;
}
.col-1 {
  width: 4.333333%;
}
.col-2 {
  width: 11.666667%;
}
/*
    This classes are for the directive. 
    For each element observed by our directive, the before-enter class is added.
  */
.before-enter {
  opacity: 0;
  transform: scale(0.5) rotatex(-25deg);
  transition: all 1s ease-out;
}
/* 
    If the element intersects with the viewport, the before-enter class is added.
  */
.enter {
  opacity: 1;
  transform: scale(1) rotatex(0deg);
}
@media only screen and (min-width: 200px) and (max-width: 767px) {
  .main {
    padding-top: 0.75rem;
    padding-bottom: 0rem;
    padding-right: 0rem;
    padding-left: 0rem;
  }
  .sections {
    display: block;
  }
  .seperator {
    display: block;
  }
  .seperator2 {
    display: none;
  }
  .footerEnd1 {
    display: none;
  }
  .footerEnd2 {
    display: block;
    height: 5rem;
  }
  .col-1 {
    width: 50%;
  }
  .col-2 {
    width: 50%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1150px) {
  .main {
    padding: 2rem;
  }
  .col-2 {
    width: 20.666667%;
  }
  .col-1 {
    width: 7.9%;
  }
}
</style>
